import {v4 as uuidv4} from 'uuid';
import img1 from '../../assets/img/team/Galeri.png';
import img2 from '../../assets/img/team/Galeri1.png';
import img3 from '../../assets/img/team/Galeri2.png';
import img4 from '../../assets/img/team/Galeri3.png';
import img5 from '../../assets/img/team/Galeri4.png';
import img6 from '../../assets/img/team/Galeri5.png';


const teamTwoData = [
    {
        id: uuidv4(),
        img: img1,
        name: 'Proje Fotoğrafı',
        designation: 'Açıklama',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: '#'
    },

    {
        id: uuidv4(),
        img: img2,
        name: 'Proje Fotoğrafı',
        designation: 'Engineer',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: '#'
    },

    {
        id: uuidv4(),
        img: img3,
        name: 'Proje Fotoğrafı',
        designation: 'Mechanic',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: '#'
    },

    {
        id: uuidv4(),
        img: img4,
        name: 'Proje Fotoğrafı',
        designation: 'Contractor',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: '#'
    },

    {
        id: uuidv4(),
        img: img5,
        name: 'Proje Fotoğrafı',
        designation: 'Manager',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: '#'
    },
    {
        id: uuidv4(),
        img: img6,
        name: 'Proje Fotoğrafı',
        designation: 'Manager',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: '#'
    },

    
]

export default teamTwoData;