import { isValidElement } from 'react';
import {v4 as uuidv4} from 'uuid';

const faqOneData = [
    {
        id: uuidv4(),
        ques: 'Projenin Maliyeti Ne Kadar?',
        ans: 'Proje bütçesi, madencilik ekipmanları, personel masrafları ve çevresel düzenlemeler göz önünde bulundurularak belirlenir.',
        index: 'faq1',
    },

    {
        id: uuidv4(),
        ques: 'Çevresel Etkileri Nasıl Yönetiyorsunuz?',
        ans: 'Çevresel etki değerlendirmesi yapılır ve uygun önlemler alınır. Reklamasyon planları ve sürdürülebilirlik stratejileri uygulanır.',
        index: 'faq2'
    },

    {
        id: uuidv4(),
        ques: 'Toplumsal Kabul ve İşbirliği Nasıl Sağlanıyor?',
        ans: 'Yerel topluluklarla etkili iletişim kurulur ve ihtiyaçlarını anlamak için yerel katılım sağlanır. Sosyal fayda projeleri ve istihdam fırsatları sunulur.',
        index: 'faq3'
    },

    {
        id: uuidv4(),
        ques: 'Teknoloji ve Verimlilikte Hangi İlerlemeler Var?',
        ans: 'En son teknolojiler ve otomasyon sistemleri kullanılarak verimlilik artırılır. Veri analitiği ve yapay zeka gibi yenilikçi çözümler entegre edilir.',
        index: 'faq4'
    },

    {
        id: uuidv4(),
        ques: 'Riskler ve Güvenlik Nasıl Yönetiliyor?',
        ans: 'Risk yönetimi planları oluşturulur ve uygulanır. İşçi sağlığı ve güvenliği standartlarına sıkı bir şekilde uyulur.',
        index: 'faq5'
    },

]

export default faqOneData;