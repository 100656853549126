import videoBg from '../../assets/img/home1/200x250.png';
import imgBlock1 from '../../assets/img/home1/540x620.png';
import tabImg1 from '../../assets/img/home1/materyal.png'
import tabImg2 from '../../assets/img/home1/Teknoloji.png'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import 'react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video';


const AboutOne = ({pt}) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <>
        {/* <ModalVideo
            channel="youtube"
            autoplay
            isOpen={isOpen}
            videoId="EZ9DrY43wtw"
            onClose={() => setOpen(false)}
        /> */}
        <section className={pt ? 'about-section section-padding' : 'about-section section-padding pt-0'}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-5 col-12">
                        <div className="img-block-pop-video">
                            <img src={imgBlock1} alt="busico"/>
                            <div className="popup-video-block d-flex justify-content-center align-items-center bg-cover" style={{backgroundImage: `url(${videoBg})`}}>
                                <div className="video-play-btn">
                                    <span className="popup-video" onClick={() => setOpen(true)} style={{cursor: 'pointer'}}><i className="fas fa-play"></i></span>                     
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-7 col-md-10 col-12">
                        <div className="block-contents">
                            <div className="section-title">
                                <span>01. Hakkımızda</span>
                                <h2>Lider Madencilik Çözümleri</h2>
                            </div>
                            <p>Köklü tecrübemiz ve yenilikçi yaklaşımımızla madencilik sektöründe lideriz. Sürdürülebilirlik ve kaliteye odaklı çözümler sunuyoruz. Sürdürülebilirlik ve kaliteye odaklı yenilikçi çözümlerle müşteri memnuniyetini ve endüstri standartlarını üst seviyede tutuyoruz.</p>
                        </div>
                        <div className="tab-content-block">
                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="pills-technology-tab" data-bs-toggle="pill" data-bs-target="#pills-technology" type="button" role="tab" aria-controls="pills-technology" aria-selected="true">Teknoloji</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-materials-tab" data-bs-toggle="pill" data-bs-target="#pills-materials" type="button" role="tab" aria-controls="pills-materials" aria-selected="false">Materyaller</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-approved-tab" data-bs-toggle="pill" data-bs-target="#pills-approved" type="button" role="tab" aria-controls="pills-approved" aria-selected="false">Onaylananlar</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-technology" role="tabpanel" aria-labelledby="pills-technology-tab">
                                    <div className="tab-inner-contents">
                                        <div className="img-box">
                                            <img src={tabImg2} alt=""/>
                                        </div>
                                        <div className="checked-features-list">
                                            <ul>
                                                <li>Otomasyon ve Uzaktan Kontrol Sistemleri</li>
                                                <li>Sondaj Teknolojileri</li>
                                                <li>Bulut Tabanlı Veri Analitiği</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-materials" role="tabpanel" aria-labelledby="pills-materials-tab">
                                    <div className="tab-inner-contents">
                                        <div className="img-box">
                                            <img src={tabImg1} alt=""/>
                                        </div>
                                        <div className="checked-features-list">
                                            <ul>
                                                <li>Kömür</li>
                                                <li>Metal Cevherleri</li>
                                                <li>Agrega</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-approved" role="tabpanel" aria-labelledby="pills-approved-tab">
                                    <div className="tab-inner-contents">
                                        <div className="img-box">
                                            <img src="assets/img/home1/tab-img.jpg" alt=""/>
                                        </div>
                                        <div className="checked-features-list">
                                            <ul>
                                                <li>Altın</li>
                                                <li>Demir Cevheri</li>
                                                <li>Bakır</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>                          
                        </div>
                        <Link to="/about" className="plus-text-btn mt-lg-5 mt-4">
                            <div className="icon">
                                <i className="fas fa-plus"></i>
                            </div>
                            <div className="link-text">
                                <span>Hakkımızda</span> Daha Fazlasını Keşfedin!
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default AboutOne;