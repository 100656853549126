import {v4 as uuidv4} from 'uuid';
import img1 from '../../assets/img/blog/pp2.jpg';

const feedsData = [
    {
        id: uuidv4(),
        img: img1,
        title: 'Çelik sektöründe toparlanma ikinci yarıda bekleniyor',
        meta: '24th March 2019',
        link: '#'
    },

    {
        id: uuidv4(),
        img: img1,
        title: 'Uludağ İhracatçı Birlikleri hedef büyüttü',
        meta: '24th March 2019',
        link: '#'
    },

    {
        id: uuidv4(),
        img: img1,
        title: 'EMİB iş sağlığı ve güvenliğinde dijital farkındalığa imza attı',
        meta: '24th March 2019',
        link: '#'
    },

    {
        id: uuidv4(),
        img: img1,
        title: 'Maden Türkiye Fuarı kapılarını açtı',
        meta: '24th March 2019',
        link: '#'
    },
    
]

export default feedsData