import {v4 as uuidv4} from 'uuid';
import img1 from "../assets/img/new-detail/Haberdetay – 1.png"
import img2 from "../assets/img/new-detail/Haber detay – 2.png"
import img3 from "../assets/img/team/Galeri1.png"
import img4 from "../assets/img/team/Galeri2.png"

const projectDetailsData = [
    {
        id: uuidv4(),
        title: "Çöpler Altın Madeni, Erzincan",
        date : "24 Mart 2023",
        description_1 : "Çöpler Altın, Ankara'nın 550 km doğusunda ve Türkiye'nin doğusundaki Erzincan şehrinin 120 km güneybatısında yer alan bir açık ocak madenidir. Yüzde 95'i Alacer Gold'a, yüzde 5'i ise Türkiye merkezli Lydia Maden Isletmelers'e ait. Alacer Gold, Anatolia Minerals Development ve Avoca Resources'ın Şubat 2011'de birleşmesiyle oluşmuştur.Anatolia Minerals, Eylül 1998'de Çöpler Altın Madeni'nin hisselerini satın aldı ve Ocak 2004'te %100 kontrolü ele geçirdi. Birleşme sonrasında Alacer Gold, madendeki yüzde beş hissesini Ağustos 2009'da Lydia Maden'e sattı.Projenin mali kapanışı Nisan 2009'da gerçekleşti ve Türkiye'de finansman alan ilk maden olduğu iddia ediliyor. Projenin tahmini maliyeti 250 milyon dolar. UniCredit projenin sponsorudur.",
        title_2 : "Türkiye madeninin altın rezervleri ve jeolojisi",
        description_2 :"Aralık 2011 itibarıyla Çöpler Altın Madeni'nde kanıtlanmış ve muhtemel rezervler 95,4 milyon ton olup, ortalama tenör 1,4 g/t altındır.PFS raporuna göre bölgedeki altın rezervleri 2,2 milyon onstan (Moz) 4,6 Moz'a yükseldi. Maden, düşük sülfidasyonlu epitermal altın yatağıdır. Her ikisi de Dünya yüzeyinin altında oluşan magmatik kayalar olan diyorit ila monzonit porfir stokunun bir bileşimi ile çevrilidir.",
        title_3 : "Çöpler yatağındaki altın ve cevherin işlenmesi",
        description_3 :"Açık ocaktan çıkarılan oksit cevheri, basit bir kırma, aglomerasyon ve yığın liçi devresi yoluyla işleniyor.Daha derindeki sülfit cevherleri, geleneksel kırma ve öğütme kullanılarak işlenecek ve ardından basınçlı oksidasyon devresine gönderilecek.Basınçlı oksidasyon devresi günde 8.000 ton cevher kapasitesine sahip olacak. Toplam 33,1 milyon ton sülfit cevherinden altının %94,4'ü ve bakırın %75,2'si geri kazanılacak.",
        banner : "Çöpler Altın Madeni: Gelişim ve Üretim Potansiyeli",
        project_img: img1,
        project_img_2: img3,
        project_img_3: img3,
        question_1 : "Çöpler Altın Madeni nerede bulunuyor ve sahipleri kimlerdir?",
        answer_1 : "Çöpler Altın Madeni, Türkiye'nin doğusundaki Erzincan şehrinin 120 km güneybatısında, Ankara'nın 550 km doğusunda yer almaktadır. Madenin %95'i Alacer Gold'a, %5'i ise Türkiye merkezli Lydia Maden Isletmelers'e aittir.", 
        question_2 : "Çöpler Altın Madeni projesi ne zaman başladı ve maliyeti nedir?",
        answer_2 : "Projenin inşaat çalışmaları Eylül 2009'da başlamış ve ticari üretime Nisan 2011'de geçilmiştir. Tahmini maliyeti 250 milyon dolar olarak belirlenmiştir.",
        question_3 : "Çöpler Altın Madeni'nde hangi yöntemlerle altın çıkarılıyor?",
        answer_3 : "Madende oksit cevheri basit bir kırma, aglomerasyon ve yığın liçi devresi yoluyla işlenirken, daha derindeki sülfit cevherleri geleneksel kırma ve öğütme kullanılarak işlenecek ve ardından basınçlı oksidasyon devresine gönderilecektir.",
        question_4 : "Madenin üretim kapasitesi ve geleceğe yönelik beklentiler nelerdir?",
        answer_4 : " Maden, günde 5.000 ton (tpd) ve 10.000 ton/gün yığın liçi kapasiteli bir değirmen verimi için tasarlanmıştır. 2011 yılında 113.861 ons altın üretmiştir. Alacer, 2015 yılına kadar yılda 800.000 ons altın üretmeyi planlamaktadır.",
    },

    {
        id: uuidv4(),
        title: "Öksüt Altın Projesi",
        date : "24 Mart 2023",
        description_1 : "Öksüt projesi, Türkiye'nin İç Anadolu bölgesinde bulunan yüksek sülfidasyonlu bir epitermal altın madenidir.Maden, Centerra Gold'un yüzde yüz iştiraki olan Öksüt Madencilik Sanayi ve Ticaret'e (OMAŞ) ait ve işletilmektedir. Centerra, 2013 yılında Stratex'ten kalan %30 hisseyi satın alarak projede %100 pay elde etti.221 milyon dolarlık proje, Avrupa İmar ve Kalkınma Bankası (EBRD) ve UniCredit tarafından ortaklaşa finanse ediliyor. UniCredit ile sağlanan 150 milyon dolarlık proje finansmanı kapsamında EBRD'den 75 milyon dolarlık kredi aldı.",
        title_2 : "Öksüt altın projesi lokasyonu",
        description_2 :"Öksüt altın madeni, Kayseri'nin 48 km güneyinde ve Ankara'nın 295 km güneydoğusunda, İç Anadolu'nun kırsal bölgesindeki Develi ilçesinde yer almaktadır.Proje sahası, Develi Dağları'nda 1.800 m yükseklikte, kuzey-güney doğrultulu bir topoğrafik yükseklikte yer almaktadır. Uluslararası ve yerli havayollarının hizmet verdiği Ankara ve Kayseri uluslararası havaalanları üzerinden ulaşılabilir.",
        title_3 : "Öksüt altın projesinin jeolojisi ve cevherleşmesi",
        description_3 :"Öksüt madeni, Güneydoğu Avrupa'dan Türkiye, Kafkaslar ve Pakistan'a kadar uzanan Tetis Metalojenik Kuşağı içerisinde yer almaktadır.Geç Kretase döneminde Arap Plakası ile Avrasya Plakası arasındaki çarpışma nedeniyle Tetis Okyanusu'nun kapanması, bölgede magmatik aktiviteye ve buna bağlı cevher oluşum süreçlerine yol açtı.",
        banner : "Öksüt altın projesinde madencilik yöntemi",
        project_img: img1,
        project_img_2: img3,
        project_img_3: img3,
        question_1 : "Öksüt projesinin finansmanı nasıl sağlanmıştır?",
        answer_1 : "Proje, Avrupa İmar ve Kalkınma Bankası (EBRD) ve UniCredit tarafından ortaklaşa finanse edilmiştir. UniCredit ile sağlanan 150 milyon dolarlık proje finansmanı kapsamında EBRD'den 75 milyon dolarlık kredi alınmıştır.", 
        question_2 : "Öksüt madeninin keşfi ne zaman yapılmıştır ve inşaat ne zaman başlamıştır?",
        answer_2 : "Madenin keşfi 2007 yılında gerçekleşmiştir ve inşaat çalışmalarına fizibilite çalışmasının tamamlanmasının ardından Mart 2018'de başlanmıştır.",
        question_3 : "Öksüt madeninin ticari üretime geçişi ne zaman olmuştur?",
        answer_3 : "Madenden ilk altın dökümü Ocak 2020'de gerçekleşmiş ve proje Mayıs 2020'de ticari üretime geçmiştir.",
        question_4 : "Öksüt maden rezervleri hakkında ne biliniyor?",
        answer_4 : "Aralık 2019 itibarıyla, proje 1,27 milyon ons (Moz) altın için 1,35 g/t dereceli 29,36 milyon ton (Mt) kanıtlanmış ve olası rezerv içermektedir.",
    },

    // {
    //     id: uuidv4(),
    //     thumb: thumb3,
    //     title: 'Beyond Outpaces Stock Market You Should Know',
    //     catName: 'Marketing',
    //     btnLink: '/newsDetails',
    //     authorName: 'Busico',
    //     authorImg: authorImg,
    //     date: '22 Feb 2022',
    //     comments: '05 Comments'
    // },
]

export default projectDetailsData;