import {v4 as uuidv4} from 'uuid';
import img1 from "../assets/img/new-detail/Haberdetay – 1.png"
import img2 from "../assets/img/new-detail/Haber detay – 2.png"
import portfolio1 from '../assets/img/project/Bakircinko.png';
import portfolio2 from '../assets/img/project/lityum.png';
import portfolio3 from '../assets/img/project/elmas.png';
import portfolio4 from '../assets/img/project/Lityumproje.png';
const projectData = [
    // {
    //     id: uuidv4(),
    //     title: "Türkiye'nin ilk bor karbür tesisi 19 Mart’ta Bandırma’da açılacak",
    //     date : "24 Mart 2023",
    //     description_1 : "Türkiye'nin ilk ve stratejik tesislerinden biri olacak bor karbür tesisi, Balıkesir'in Bandırma ilçesinde Cumhurbaşkanı Recep Tayyip Erdoğan'ın katılımıyla 19 Mart Pazar günü açılacak. Enerji ve Tabii Kaynaklar Bakanlığından yapılan açıklamaya göre, dünya bor rezervinin yüzde 73'üne sahip olan Türkiye'de, Eti Maden geçen yıl 1 milyar 321 milyon dolarlık satış geliriyle rekor kırdı",
    //     project_img_data: img1,
      
    // },

    // {
    //     id: uuidv4(),
    //     title: "Kritik minerallerin piyasa değerini yükseliyor",
    //     date : "24 Mart 2023",
    //     description_1 : "Dünyada yeşil dönüşüm için gerekli temiz enerji teknolojilerinde kullanılan kritik minerallerin piyasa büyüklüğü 2040'a kadar yüzde 137 artarak 770 milyar dolara ulaşacak.",
    //     project_img_data: img2,
       
    // },

    // {
    //     id: uuidv4(),
    //     thumb: thumb3,
    //     title: 'Beyond Outpaces Stock Market You Should Know',
    //     catName: 'Marketing',
    //     btnLink: '/newsDetails',
    //     authorName: 'Busico',
    //     authorImg: authorImg,
    //     date: '22 Feb 2022',
    //     comments: '05 Comments'
    // },

    {
        id: uuidv4(),
        img: portfolio1,
        category: 'ARCHITECTS',
        title: 'Çöpler Altın Madeni, Erzincan',
        date: 'Jan 05 2013'
    },

    {
        id: uuidv4(),
        img: portfolio2,
        category: 'DESIGN',
        title: 'Öksüt Altın Projesi',
        date: 'May 25 2010'
    },

    {
        id: uuidv4(),
        img: portfolio3,
        category: 'Interior',
        title: 'Argyle Elmas Madeni',
        date: 'Jul 25 2012'
    },

    {
        id: uuidv4(),
        img: portfolio4,
        category: 'Decoration',
        title: 'Authier Lityum Projesi',
        date: 'Nov 28 2018'
    },
]

export default projectData;