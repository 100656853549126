import React from 'react'
import icon1 from '../assets/img/icons/t1.png';
import icon2 from '../assets/img/icons/t2.png';


const Timeline = () => {
  return (
    <section className="company-timeline-wrapper section-padding">
        <div className="container">
            <div className="row mtm-40">
                <div className="col-lg-4  col-md-6 offset-lg-1 pe-lg-0">
                    <div className="timeline-content text-lg-end">
                        <div className="single-timeline-box">
                            <span>1990 - 1995</span>
                            <h6>Ar-Ge Odaklı Büyüme</h6>
                            <p>Maden şirketi, 1990'larda yoğun Ar-Ge çalışmaları yaparak yeni rezervler keşfetti ve üretim kapasitesini artırdı.</p>
                        </div>
                        <div className="single-timeline-box">
                            <span>1990 - 1995</span>
                            <h6>Teknoloji ve Yatırımın Gücü</h6>
                            <p>Büyük yatırımlar ve teknolojik gelişmeler, maden şirketinin 1990'larda hızla büyümesini sağladı ve sektörde öncü konuma yükselmesine olanak tanıdı.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 d-none mt-40 d-lg-block">
                    <div className="time-line-icon">
                        <div className="single-icon">
                            <img src={icon1} alt=""/>
                        </div>
                        <div className="single-icon">
                            <img src={icon2} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 ps-lg-0">
                    <div className="timeline-content">
                        <div className="single-timeline-box">
                            <span>1988 - 2006</span>
                            <h6>Küresel Arenada Stratejik Genişleme</h6>
                            <p>Maden şirketi stratejik bir genişleme sürecine girdi ve uluslararası pazarda etkin bir oyuncu haline geldi.</p>
                        </div>
                        <div className="single-timeline-box">
                            <span>2010 - 2020</span>
                            <h6>Sürdürülebilirlikte Öncü Adımlar</h6>
                            <p>2010'lardan itibaren maden şirketi, sürdürülebilirlik ilkelerine odaklanarak çevresel etkilerini azaltma ve toplumsal sorumluluk projelerine yatırım yapma konusunda öncü oldu.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-lg-5 mt-3">
                <div className="col-md-6 col-12 col-lg-4">
                    <div className="single-icon-border-box">
                        <div className="icon">
                            <i className="flaticon-trust"></i>
                        </div>
                        <div className="content">
                            <h6><span>Dünya Çapında</span> Güvenilir</h6>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12 col-lg-4">
                    <div className="single-icon-border-box">
                        <div className="icon">
                            <i className="flaticon-cashier-machine"></i>
                        </div>
                        <div className="content">
                            <h6><span>Devlet Tarafında</span> Kayıtlı</h6>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12 col-lg-4">
                    <div className="single-icon-border-box">
                        <div className="icon">
                            <i className="flaticon-goal"></i>
                        </div>
                        <div className="content">
                            <h6><span>Başarı İçin</span>Misyon Perspektifi</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Timeline