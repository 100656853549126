import {v4 as uuidv4} from 'uuid';

const workProcessData = [
    {
        id: uuidv4(),
        icon: 'writing',
        // subTitle: 'Sketch',
        title: 'Madencilik Faaliyetleri ve Endüstri',
        desc: 'Madencilik faaliyetleri, endüstriyel süreçlerle doğal kaynakların çıkarılması, işlenmesi ve kullanılmasını içerir. Ekonomiye önemli katkılar sağlar.',
    },

    {
        id: uuidv4(),
        icon: 'flow-chart',
        // subTitle: 'Project',
        title: 'Çevresel Etkiler ve Sürdürülebilirlik',
        desc: 'Madencilik faaliyetleri çevresel etkileriyle doğal yaşamı etkiler. Sürdürülebilirlik, çevreye zarar vermeden madencilik uygulamalarını dengeler.',
    },    

    {
        id: uuidv4(),
        icon: 'interior-design',
        // subTitle: 'Architect',
        title: 'Yasal Düzenlemeler ve Politikalar',
        desc: 'Yasal düzenlemeler ve politikalar, çevresel, sosyal ve ekonomik açılardan madencilik faaliyetlerini kontrol eder, etkilerini azaltır.',
    },    
]

export default workProcessData;