import { Link } from 'react-router-dom';
import mainLogo from '../../assets/img/promad_logo.svg';

const OffsetMenu = ({offset, handleOffset}) => {
    return (
        <>
        <div className={offset? 'offset-menu': 'offset-menu show'}>
            <span onClick={handleOffset} id="offset-menu-close-btn"><i className="fal fa-plus"></i></span>
            <div className="offset-menu-wrapper text-white">
                <div className="offset-menu-header">
                    <div className="offset-menu-logo">
                        <Link to="/"><img src={mainLogo} alt="logo"/></Link>
                    </div>
                </div> 
                <div className="offset-menu-section">
                    <h3>Hakkımızda</h3>
                    <p>Köklü tecrübemiz ve yenilikçi yaklaşımımızla madencilik sektöründe lideriz. Sürdürülebilirlik ve kaliteye odaklı çözümler sunuyoruz. Sürdürülebilirlik ve kaliteye odaklı yenilikçi çözümlerle müşteri memnuniyetini ve endüstri standartlarını üst seviyede tutuyoruz.</p>
                    <Link to="/contact" className="theme-btn  mt-30">Danışma</Link>
                </div>
                <div className="offset-menu-section">
                    <h3>İletişim Bilgileri</h3>
                    <ul>
                        <li><span><i className="fal fa-map"></i></span>Turkiye</li>
                        <li><span><i className="fal fa-phone"></i></span>(000) 000-000-0000</li>
                        <li><span><i className="fal fa-envelope"></i></span>info@promad.com</li>
                        <li><span><i className="fal fa-clock"></i></span>Haftaiçi: 09.00 - 18.00</li>
                        {/* <li><span><i className="fal fa-clock"></i></span>Sunday: Closed</li> */}
                    </ul>
                </div> 
                <div className="offset-menu-footer">
                    <div className="offset-menu-social-icon">
                        <a href="#"><i className="fab fa-facebook-f"></i></a>
                        <a href="#"><i className="fab fa-twitter"></i></a>
                        <a href="#"><i className="fab fa-linkedin-in"></i></a>
                        <a href="#"><i className="fab fa-dribbble"></i></a>
                        <a href="#"><i className="fab fa-youtube"></i></a>
                    </div>
                </div>    
            </div>               
        </div>
        </>
    )
}

export default OffsetMenu;