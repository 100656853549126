import {v4 as uuidv4} from 'uuid';

const tagsData = [
    {
        id: uuidv4(),
        link: '#',
        label: 'Maden'
    },

    {
        id: uuidv4(),
        link: '#',
        label: 'Cevher'
    },

    {
        id: uuidv4(),
        link: '#',
        label: 'Endüstri'
    },

    {
        id: uuidv4(),
        link: '#',
        label: 'Kömür'
    },

    {
        id: uuidv4(),
        link: '#',
        label: 'Metal'
    },

    {
        id: uuidv4(),
        link: '#',
        label: 'Taşocak'
    },

    {
        id: uuidv4(),
        link: '#',
        label: 'Doğal Kaynak'
    },

    {
        id: uuidv4(),
        link: '#',
        label: 'Enerji'
    },
]

export default tagsData;
