import {v4 as uuidv4} from 'uuid';
import thumb1 from '../../assets/img/blog/740x460.png';
import thumb2 from '../../assets/img/blog/asset-4.png';
import thumb3 from '../../assets/img/blog/asset-2.png';
import authorImg from '../../assets/img/blog/teammember2.png';
import authorImg1 from '../../assets/img/blog/teammember2.png';
const blogOneData = [
    {
        id: uuidv4(),
        thumb: thumb1,
        title: 'Madencilik sektörünün sorunları ağırlaşıyor',
        btnText: 'Devamını oku',
        btnLink: '/newsDetails',
        authorName: 'Busico',
        authorImg: authorImg,
        date: '28 MAY 2022'
    },

    {
        id: uuidv4(),
        thumb: thumb2,
        title: 'Krom ihracatı % 20 arttı ve daha artacak',
        btnText: 'Devamını oku',
        btnLink: '/newsDetails',
        authorName: 'Salman',
        authorImg: authorImg1,
        date: '17 NOV 2022'
    },

    {
        id: uuidv4(),
        thumb: thumb3,
        title: 'Uranyum talebini artırıyor',
        btnText: 'Devamını oku',
        btnLink: '/newsDetails',
        authorName: 'Busico',
        authorImg: authorImg,
        date: '20 JAN 2020'
    },
]

export default blogOneData;