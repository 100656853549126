import React from 'react'
import { Link } from 'react-router-dom'
import img1 from '../../assets/img/blog/post-inner.jpg';
import img2 from '../../assets/img/blog/p1.jpg';
import post1 from '../../assets/img/new-detail/Haber detay.png';
import post2 from '../../assets/img/new-detail/Haber detay – 3.png';
import author1 from '../../assets/img/blog/author_img.jpg'
import author2 from '../../assets/img/blog/author2.jpg';
import CommentForm from './CommentForm';
import Sidebar from './Sidebar';
import { useParams } from 'react-router-dom';
import newsDetailsData from '../../data/newsDetailsData';
const Details = () => {
    const {title} = useParams();

    function removeTurkishChars(text) {
        const turkishChars = {
          ğ: "g",
          Ğ: "G",
          ü: "u",
          Ü: "U",
          ş: "s",
          Ş: "S",
          ı: "i",
          İ: "I",
          ö: "o",
          Ö: "O",
          ç: "c",
          Ç: "C",
        };
    
        return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, (match) => turkishChars[match]);
      }

    const news = newsDetailsData.find(
        (newData) =>
          removeTurkishChars(newData.title).replace(/\s+/g, "-").toLowerCase() ===
          title
      );

      if (!news) {
        return <p>Haber Bulunamadı.</p>;
      }


  return (
    <section className="blog-wrapper news-wrapper section-padding">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-12">
                    <div className="blog-post-details border-wrap">
                        {/* {newsDetailsData.map((newsDetails)=>( */}
                              <div className="single-blog-post post-details" key={news.id}>                            
                              <div className="post-content">
                                  <div className="post-cat">
                                      {/* <Link to="#">BUILDING</Link> */}
                                  </div>
                                  <h2>{news.title}</h2>
                                  <div className="post-meta">
                                      {/* <span><i className="fal fa-comments"></i>35 Comments</span> */}
                                      <span><i className="fal fa-calendar-alt"></i>{news.date}</span>
                                  </div>
  
                                  <p>{news.description_1}</p>
                                  <p>{news.description_2}</p>
                                  <img src={news.news_img} alt=""/>
                                  <h3>{news.title_2}</h3>
                                  <p>{news.description_3}</p>
                                  <blockquote>
                                  {news.banner}
                                  </blockquote>
                                  <p>{news.description_4}</p>
      
                                  {/* <ul>
                                      <li>We track every dollar</li>
                                      <li>We’re an open book</li>
                                      <li>100% goes to the field</li>
                                      <li>Received the highest grades</li>
                                  </ul>                                 */}
                                  <h3>{news.title_3}</h3>
                                  <p>{news.description_5}</p>
                                  {/* normalde burada 370x300 var ama ben buraya 37x440 koydum */}
                                  <img className="alignleft" src={news.news_img_2} alt=""/>
  
                                  <p>{news.description_6}</p>
                                  
                                  <p>{news.description_7}</p>
                              </div>
                          </div>
                        {/* ))} */}
                      
                        <div className="row tag-share-wrap">
                            {/* <div className="col-lg-8 col-12">
                                <h4>İlgili Etiketler</h4>
                                <div className="tagcloud">                                   
                                    <Link to="/newsDetails">Maden</Link>
                                    <Link to="/newsDetails">Kömür</Link>
                                    <Link to="/newsDetails">Bor</Link>
                                </div>
                            </div> */}
                            <div className="col-lg-12 col-12 mt-3 mt-lg-0 text-lg-start">
                                <h4>Haberi Paylaşın</h4>
                                <div className="social-share">
                                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                    <a href="#"><i className="fab fa-linkedin-in"></i></a>                                    
                                </div>
                            </div>
                        </div>
    
                        <div className="related-post-wrap">
                            <h3>Bağlantılı İçerikler</h3>
    
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="single-related-post">
                                        {/* normalde burası 740x460 ama ben 740x382 */}
                                        <div className="featured-thumb bg-cover" >
                                            <img src={post1} style={{width:'100%',height:'100%'}}/>
                                        </div>
                                        <div className="post-content">
                                            <div className="post-date">
                                                <span><i className="fal fa-calendar-alt"></i>27th March 2022</span>
                                            </div>
                                            <h4><a href="/news">MAPEG, 222 maden sahasını ihale edilecek</a></h4>
                                            <p>Enerji ve Tabii Kaynaklar Bakanlığı Maden ve Petrol İşleri Genel Müdürlüğü'nce (MAPEG) 222 maden sahası ihale edilecek.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="single-related-post">
                                        <div className="featured-thumb bg-cover">
                                        <img src={post2} style={{width:'100%',height:'100%'}}/>

                                        </div>
                                        <div className="post-content">
                                            <div className="post-date">
                                                <span><i className="fal fa-calendar-alt"></i>24th July 2020</span>
                                            </div>
                                            <h4><Link to="/news">Tekirdağ ve Şırnak'ta 2 maden sahası ihale edilecek</Link></h4>
                                            <p>Enerji ve Tabii Kaynaklar Bakanlığı Maden ve Petrol İşleri Genel Müdürlüğünce (MAPEG), 4'üncü grup 2 maden sahası ihale edilecek.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        {/* <div className="comments-section-wrap pt-40">
                            <div className="comments-heading">
                                <h3>03 Comments</h3>
                            </div>
                            <ul className="comments-item-list">
                                <li className="single-comment-item">
                                    <div className="author-img">
                                        <img src={author1} alt=""/>
                                    </div>
                                    <div className="author-info-comment">
                                        <div className="info">
                                            <h5><a href="#">Rosalina Kelian</a></h5>
                                            <span>19th May 2018</span>
                                            <a href="#" className="theme-btn minimal-btn"><i className="fal fa-reply"></i>Reply</a>
                                        </div>
                                        <div className="comment-text">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Ut enim ad minim veniam, quis nostrud  laboris nisi ut aliquip ex ea commodo consequat.</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="single-comment-item">
                                    <div className="author-img">
                                        <img src={author2} alt=""/>
                                    </div>
                                    <div className="author-info-comment">
                                        <div className="info">
                                            <h5><a href="#">Arista Williamson</a></h5>
                                            <span>21th Feb 2020</span>
                                            <a href="#" className="theme-btn minimal-btn"><i className="fal fa-reply"></i>Reply</a>
                                        </div>
                                        <div className="comment-text">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco nisi ut aliquip ex ea commodo consequat.</p>
                                        </div>
                                    </div>
    
                                    <ul className="replay-comment">
                                        <li className="single-comment-item">
                                            <div className="author-img">
                                                <img src={author1} alt=""/>
                                            </div>
                                            <div className="author-info-comment">
                                                <div className="info">
                                                    <h5><a href="#">Salman Ahmed</a></h5>
                                                    <span>29th Jan 2022</span>
                                                    <a href="#" className="theme-btn minimal-btn"><i className="fal fa-reply"></i>Reply</a>
                                                </div>
                                                <div className="comment-text">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam..</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
    
                        <div className="comment-form-wrap mt-40">
                            <h3>Post Comment</h3>
                            <CommentForm/>
                        </div> */}
                        
                    </div>
                </div>
                {/* <Sidebar/> */}
            </div>
        </div>
    </section>
  )
}

export default Details