import React from 'react'
import { Link } from "react-router-dom";
import image1 from '../../assets/img/project/Proje_detay1.png';
import image2 from '../../assets/img/project/Proje detay2.png';
import image3 from '../../assets/img/project/Proje detay3.png';
import { useParams } from 'react-router-dom';
import projectDetailsData from '../../data/projectDetailsData';

const Details = () => {
    const { title } = useParams();

    function removeTurkishChars(text) {
        const turkishChars = {
            ğ: "g",
            Ğ: "G",
            ü: "u",
            Ü: "U",
            ş: "s",
            Ş: "S",
            ı: "i",
            İ: "I",
            ö: "o",
            Ö: "O",
            ç: "c",
            Ç: "C",
        };

        return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, (match) => turkishChars[match]);
    }

      const projects = projectDetailsData.find(
        (projectData) =>
          removeTurkishChars(projectData.title).replace(/\s+/g, "-").toLowerCase() ===
          title
      );

      if (!projects) {
        return <p>Proje Bulunamadı.</p>;
      }


    return (
        <section className="service-details-wrapper section-padding">
            <div className="container">
                <div className="row">
                    {/* <div className="col-lg-3 col-md-4 mt-5 mt-md-0 col-12 order-2 order-md-1">
            <div className="service-details-sidebar">
            <div className="single-service-sidebar">
                <div className="sidebar-title">
                <h3>Proje Kategorileri</h3>
                </div>
                <ul>
                    <li><Link to="#">01. Metalik Madenler</Link></li>
                    <li><Link to="#">02. Cevher Madenciliği</Link></li>
                    <li><Link to="#">03. Taşocakçılığı</Link></li>
                    <li><Link to="#">04. Enerji Kaynakları Madenciliği</Link></li>
                    <li><Link to="#">05. Katkı Maddeleri Madenciliği</Link></li>
                    <li><Link to="#">06. Elmas Madenciliği</Link></li>
                </ul>
            </div>
            <div className="single-service-sidebar">
                <div className="sidebar-title">
                <h3>Broşürleri İndir</h3>
                </div>
                <div className="brochures-download">
                <a href="#" className="theme-btn"><i className="fal fa-file-pdf" /> .PDF İndir</a>
                <a href="#" className="theme-btn"><i className="fal fa-file-word" /> .DOC İndir</a>
                <a href="#" className="theme-btn"><i className="fal fa-file-powerpoint" /> .PPT İndir</a>
                </div>
            </div>
            <div className="single-service-sidebar site_info_widget">
                <div className="sidebar-title">
                <h3>Bizimle İletişime Geçin</h3>
                </div>
                <div className="contact-us">
                <div className="single-contact-info">
                    <div className="icon">
                        <i className="fal fa-phone" />
                    </div>
                    <div className="contact-info">
                        <span>Telefon Numarası</span>
                        <p>908 9098 987 98</p>
                    </div>
                </div>
                <div className="single-contact-info">
                    <div className="icon">
                        <i className="fal fa-envelope" />
                    </div>
                    <div className="contact-info">
                    <span>Email Adresi</span>
                    <p>info@supportexam.com</p>
                    </div>
                </div>
                <div className="single-contact-info">
                    <div className="icon">
                    <i className="fal fa-map-marker-alt"/>
                    </div>
                    <div className="contact-info">
                    <span>Ofis Adresi</span>
                    <p>14/A, Ping Tower, NYC</p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div> */}
                    <div className="col-lg-12 col-md-12 ps-lg-5 col-12 order-1 order-md-2">
                        
                        <div className="service-details-content-wrapper pl-0 pl-md-4 col-12" key={projects.id}>
                            
                            <div className='col-12'>
                                <img src={image1} alt="" style={{ width: '100%', height: 'auto' }} />
                                </div>

                            <h2>{projects.title}</h2>
                            <p>{projects.description_1}</p>
                            <h2>{projects.title_2}</h2>
                            <p>{projects.description_2}</p>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <img src={image2} alt="" style={{height:'96%'}} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <img src={image3} alt="" style={{height:'96%'}} />
                                </div>
                            </div>
                            <h2>{projects.title_3}</h2>
                            <p>{projects.description_3}</p>
                            <blockquote>
                            {projects.banner}

                            </blockquote>
                        </div>
                        <div className="faq-content pl-0 pl-md-4">
                            <h2>Bu Projede Sık Sorulan Sorular</h2>
                            <div className="faq-accordion">
                                <div className="accordion no-border me-lg-5" id="accordion">
                                    <div className="accordion-item">
                                        <h4 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq1" aria-expanded="false" aria-controls="faq1">
                                            {projects.question_1}
                                            </button>
                                        </h4>
                                        <div id="faq1" className="accordion-collapse collapse show" data-bs-parent="#accordion">
                                            <div className="accordion-body">
                                            {projects.answer_1}</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h4 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq2" aria-expanded="true" aria-controls="faq2">
                                            {projects.question_2}
                                            </button>
                                        </h4>
                                        <div id="faq2" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                            <div className="accordion-body">
                                            {projects.answer_2} </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h4 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq3" aria-expanded="false" aria-controls="faq3">
                                            {projects.question_3}
                                            </button>
                                        </h4>
                                        <div id="faq3" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                            <div className="accordion-body">
                                            {projects.answer_3}</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h4 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq4" aria-expanded="false" aria-controls="faq4">
                                            {projects.question_4}
                                            </button>
                                        </h4>
                                        <div id="faq4" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                            <div className="accordion-body">
                                            {projects.answer_4}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Details