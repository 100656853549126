import {v4 as uuidv4} from 'uuid';
import img1 from "../assets/img/new-detail/Haberdetay – 1.png"
import img2 from "../assets/img/new-detail/Haber detay – 2.png"
import img3 from "../assets/img/team/Galeri1.png"
import img4 from "../assets/img/team/Galeri2.png"

const newsDetailsData = [
    {
        id: uuidv4(),
        title: "Türkiye'nin ilk bor karbür tesisi 19 Mart’ta Bandırma’da açılacak",
        date : "24 Mart 2023",
        description_1 : "Türkiye'nin ilk ve stratejik tesislerinden biri olacak bor karbür tesisi, Balıkesir'in Bandırma ilçesinde Cumhurbaşkanı Recep Tayyip Erdoğan'ın katılımıyla 19 Mart Pazar günü açılacak. Enerji ve Tabii Kaynaklar Bakanlığından yapılan açıklamaya göre, dünya bor rezervinin yüzde 73'üne sahip olan Türkiye'de, Eti Maden geçen yıl 1 milyar 321 milyon dolarlık satış geliriyle rekor kırdı",
        description_2 :"Türkiye'nin ilk ve stratejik tesislerinden biri olacak bor karbür tesisi, Balıkesir'in Bandırma ilçesinde Cumhurbaşkanı Recep Tayyip Erdoğan'ın katılımıyla 19 Mart Pazar günü açılacak. Enerji ve Tabii Kaynaklar Bakanlığından yapılan açıklamaya göre, dünya bor rezervinin yüzde 73'üne sahip olan Türkiye'de, Eti Maden geçen yıl 1 milyar 321 milyon dolarlık satış geliriyle rekor kırdı.",
        news_img: img1,
        news_img_2: img3,
        catName: 'Business',
        btnLink: '/newsDetails',
        authorName: 'Busico',
        title_2 : "YILDA 1000 TON BOR KARBÜR ÜRETİLECEK",
        description_3 : "Tam kapasite çalıştığında 279 kişinin istihdam edileceği tesiste, yılda 1000 ton bor karbür üretilecek. Elmas ve kübik bordan sonra en sert üçüncü madde olan bor karbür, başta savunma sanayisi olmak üzere birçok alanda kullanılıyor.",
        banner : "İki yıl üst üste bor ihracatında rekor kırdık",
        description_4 : "Açıklamada görüşlerine yer verilen Enerji ve Tabii Kaynaklar Bakanı Fatih Dönmez, Türkiye'nin, son yıllarda borda attığı adımların meyvelerini toplamaya başladığını belirterek, 'Bor cevherini borik asite ve atıklarını da lityuma dönüştürüp satıyoruz. Bunun dışında kullanım alanlarını da artırıyoruz. Nitekim bu attığımız adımlar bize rekorlar getiriyor. İki yıl üst üste bor ihracatında rekor kırdık. Yeni rekorlar için yeni tesisler ve katma değerli ürün portföyünün artması gerekiyordu. Bu tesisle bunu da yapmış olacağız.' değerlendirmesinde bulundu.",
        title_3 :"2050’DE PETROL YERİNE MADENE BAĞIMLI OLABİLİRİZ ",
        description_5 : "Türkiye İhracatçılar Meclisi (TİM) Maden Sektör Kurulu Başkanı ve İstanbul Maden İhracatçıları Birliği (İMİB) Yönetim Kurulu Başkanı Rüstem Çetinkaya, Türkiye’de özellikle ‘metalik madencilik’ olarak bilinen dördüncü grup madenciliğe karşı bir lobi oluştuğunu söyledi. Türkiye için temiz enerjiye geçişte dördüncü grup madenciliği ilk adım olarak gösteren Çetinkaya, “Bizi diğer sektörler için düşmanlaştırmaya çalışan bazı görüşler olduğunu görüyoruz. ",
        description_6: "Türkiye'de çevre örgütleri maalesef ‘maden çıkartılmasın’ diye maden karşıtlığı söylemlerini artırıyor. Endişeleri gidermek ve doğru bilgileri paylaşabileceğimize inandığımız için bir araya gelmek istiyoruz. Herhangi bir STK ile ya da akademisyenlerle istenilen her zamanda, her yerde ve her şartta bir araya gelmeye hazırız, bu açık teklifimiz” diye konuştu.",
        description_7 : "Karşı duruş gösteren herkesi madenlere davet eden Çetinkaya, “Madenciliğin çevreye etkilerine ilişkin doğru bilgileri paylaşabileceğimize inandığımız için bir araya gelmek istiyoruz. Bundan sonra çevreyi ve iklimi daha çok sahiplenen bir maden sektörü göreceksiniz. Türkiye için temiz enerjinin yolu madenlerden geçiyor ” yorumunu yaptı."
    },

    {
        id: uuidv4(),
        title: "Kritik minerallerin piyasa değerini yükseliyor",
        date : "24 Mart 2023",
        description_1 : "Dünyada yeşil dönüşüm için gerekli temiz enerji teknolojilerinde kullanılan kritik minerallerin piyasa büyüklüğü 2040'a kadar yüzde 137 artarak 770 milyar dolara ulaşacak.",
        description_2 :"Ülkelerin, iklim değişikliğiyle mücadele kapsamında belirledikleri net sıfır emisyon hedeflerine ulaşmak için gelecek yıllarda temiz teknolojilere daha fazla ağırlık vermesi bekleniyor. Bu da başta elektrikli araçlar, batarya teknolojileri, yenilenebilir enerji projeleri ve enerji depolama alanında kullanılan kritik minerallere daha fazla ihtiyaç duyulacağı anlamına geliyor.",
        news_img: img2,
        news_img_2: img4,
        catName: 'Business',
        btnLink: '/newsDetails',
        authorName: 'Busico',
        title_2 : "En çok talep artışı lityumda olacak  ",
        description_3 : "Söz konusu projelerde lityum, nikel, kobalt ve grafit tercih ediliyor. Ayrıca, güneş ve rüzgar enerjisi santrallerinde nadir toprak elementleri ve bakır da kullanılıyor.",
        banner : "İki yıl üst üste bor ihracatında rekor kırdık",
        description_4 : "Uluslararası Enerji Ajansı'nın 'Küresel Kritik Mineraller Görünümü' raporundan yapılan derlemeye göre, geçen yıl bu madenlere talep 33 bin 971 kiloton oldu ve bunun yüzde 24,2'sini temiz enerji kaynaklı kullanım oluşturdu.",
        title_3 :"Piyasa değeri sıralamasında bakır ilk sırada",
        description_5 : "Lityum, nikel, kobalt, grafit ve bakır madenlerine yönelik küresel talebin 2040'ta yüzde 92 artışla 65 bin 231 kilotona, talepteki temiz enerjinin payının ise yüzde 55,2'ye yükseleceği hesaplanıyor.",
        description_6: "Nadir toprak elementleri talebi ise 2023-2040 yıllarında yüzde 89 artışla 176 kilotona yükselecek. Geçen yıl talep içerisinde yüzde 18 olan temiz enerjinin payı, 2040'ta yüzde 41'e ulaşacak.",
        description_7 : "Lityum, pil ve batarya üretimiyle elektrikli arabaların yanı sıra nükleer santrallerde soğutucu olarak ve roketlerde itici kuvvet sağlamada kullanılıyor. Ayrıca lityum, seramik ve cam yapımıyla ilaç sektöründe de değerlendiriliyor."
    },

    // {
    //     id: uuidv4(),
    //     thumb: thumb3,
    //     title: 'Beyond Outpaces Stock Market You Should Know',
    //     catName: 'Marketing',
    //     btnLink: '/newsDetails',
    //     authorName: 'Busico',
    //     authorImg: authorImg,
    //     date: '22 Feb 2022',
    //     comments: '05 Comments'
    // },
]

export default newsDetailsData;