import { Link } from "react-router-dom";
import blogOneData from "./BlogOneData";

const BlogOne = () => {
    return (
        <>
            <section className="news-wrapper section-padding pt-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-12">
                        <div className="block-contents">
                            <div className="section-title">
                                <span>Blog Yazılarımız</span>
                                <h2>Son Haberler</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {
                        blogOneData.map((blog) => (
                            <div className="col-xl-4 col-md-6 col-12" key={blog.id}>
                                <div className="single-blog-card style-1">
                                    <div className="blog-featured-img bg-cover bg-center" style={{backgroundImage: `url(${blog.thumb})`}}></div>
                                    <div className="contents">
                                        <div className="post-metabar d-flex justify-content-center align-items-center">
                                            {/* <div className="post-author">
                                                <div className="author-img bg-cover bg-center" style={{backgroundImage: `url(${blog.authorImg})`}}></div>
                                                <Link to={blog.btnLink}>{blog.authorName}</Link>
                                            </div> */}
                                            <div className="post-date">
                                                <i className="fal fa-calendar-alt"></i>
                                                <Link to={blog.btnLink}>{blog.date}</Link>
                                            </div>
                                        </div>
                                        <h3><Link to={blog.btnLink}>{blog.title}</Link></h3>
                                        <Link to={blog.btnLink} className="read-more-link">{blog.btnText}</Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                   
                </div>
            </div>
        </section>
        </>
    )
}

export default BlogOne;