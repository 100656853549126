import {v4 as uuidv4} from 'uuid';
import img1 from '../../assets/img/services/Metalcevherleri.png';
import img2 from '../../assets/img/services/komur.png';
import img3 from '../../assets/img/services/Linyitvetaskomuru.png';
import img4 from '../../assets/img/services/Kumvecakil.png';
import img5 from '../../assets/img/services/Demirvecelik.png';

const servicesOneData = [
    {
        id: uuidv4(),
        bg: img1,
        icon: 'blueprint',
        title: 'Metal Cevherleri',
        desc: 'Metal cevherleri, yerkabuğunda doğal olarak bulunan ve endüstriyel olarak kullanılan değerli metallerin cevher halindeki kaynaklarıdır.',
    },

    {
        id: uuidv4(),
        bg: img2,
        icon: 'interior-design',
        title: 'Kömür',
        desc: 'Kömür, enerji üretiminde yaygın olarak kullanılan bir fosil yakıttır ve endüstriyel süreçlerde geniş bir uygulama alanına sahiptir.',
    },

    {
        id: uuidv4(),
        bg: img3,
        icon: 'home',
        title: 'Linyit ve Taşkömürü',
        desc: 'Linyit ve taşkömürü, kömür formunda bulunan doğal kaynaklardır.Fosil yakıtlar arasında yer alır ve genellikle elektrik üretimi ve endüstriyel işlemlerde kullanılır.',
    },

    {
        id: uuidv4(),
        bg: img4,
        icon: 'varnish',
        title: 'Kum ve Çakıl',
        desc: 'Kum ve çakıl, doğal taş malzemeleri arasında bulunur ve genellikle inşaat sektöründe yol yapımı, beton üretimi ve peyzaj düzenlemelerinde kullanılır.',
    },

    {
        id: uuidv4(),
        bg: img5,
        icon: 'sketch-1',
        title: 'Demir ve Çelik',
        desc: 'Demir ve çelik, endüstride yaygın olarak kullanılan metallerdir ve inşaat, otomotiv, makine imalatı gibi birçok sektörde önemli bir rol oynarlar.',
    },
    
]

export default servicesOneData;