import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import post1 from '../../assets/img/blog/895x392.png'
import post2 from '../../assets/img/blog/895x382.png'
import post3 from '../../assets/img/blog/asset-1.png'
import post4 from '../../assets/img/blog/Haber1.png'

import author1 from '../../assets/img/blog/author_img.jpg';
import ModalVideo from 'react-modal-video';
import Sidebar from './Sidebar';
import newsData from '../../data/newsData';
const NewsWrapper = () => {
    const [isOpen, setOpen] = useState(false);
    function removeTurkishChars(text) {
        const turkishChars = {
          ğ: "g",
          Ğ: "G",
          ü: "u",
          Ü: "U",
          ş: "s",
          Ş: "S",
          ı: "i",
          İ: "I",
          ö: "o",
          Ö: "O",
          ç: "c",
          Ç: "C",
        };
    
        return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, (match) => turkishChars[match]);
      }
    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="EZ9DrY43wtw"
                onClose={() => setOpen(false)}
            />
            <section className="blog-wrapper news-wrapper section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="blog-posts">
                            {newsData.map((item) => (
                                <div className="single-blog-post" key={item.id}>
                                    <div className="post-featured-thumb bg-cover" >
                                        <img src={item.news_img_data} style={{height:'100%' , width:'100%'}}/>
                                    </div>
                                    <div className="post-content">
                                        <div className="post-cat">
                                            {/* <Link to="/news">BUILDING</Link> */}
                                        </div>
                                        <h2><Link to={`/newsDetails/${removeTurkishChars(item.title)
                        .replace(/\s+/g, "-")
                        .toLowerCase()}`}>{item.title}</Link></h2>
                                        <div className="post-meta">
                                            {/* <span><i className="fal fa-comments"></i>35 Comments</span> */}
                                            <span><i className="fal fa-calendar-alt"></i>{item.date}</span>
                                        </div>
                                        <p>{item.description_1}</p>
                                        <div className="d-flex justify-content-between align-items-center mt-30">
                                            {/* <div className="author-info">
                                            <div className="author-img" style={{backgroundImage: `url(${author1})`}}></div>
                                            <h5><a href="#">by Hetmayar</a></h5>
                                        </div> */}
                                            <div className="post-link">
                                                <Link to={`/newsDetails/${removeTurkishChars(item.title)
                        .replace(/\s+/g, "-")
                        .toLowerCase()}`}><i className="fal fa-arrow-right"></i>Devamını Oku</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                                {/* <div className="single-blog-post">
                                    <div className="post-featured-thumb bg-cover" style={{ backgroundImage: `url(${post2})` }}></div>
                                    <div className="post-content">
                                        <div className="post-cat">
                                            <Link to="#">CONSTRUCTION</Link>
                                        </div>
                                        <h2><Link to="/newsDetails">Madencilik karşıtı bazı düzenlemeler sektörü zor durumda bırakıyor</Link></h2>
                                        <div className="post-meta">
                                            <span><i className="fal fa-comments"></i>35 Comments</span>
                                            <span><i className="fal fa-calendar-alt"></i>17th July 2020</span>
                                        </div>
                                        <p>Cumhurbaşkanı Yardımcısı Cevdet Yılmaz, Türkiye İhracatçılar Meclisi (TİM) Maden Sektör Kurulu Başkanı ve İstanbul Maden İhracatçıları Birliği (İMİB) Yönetim Kurulu Başkanı Rüstem Çetinkaya ile bir araya geldi.</p>
                                        <div className="d-flex justify-content-between align-items-center mt-30">
                                            <div className="author-info">
                                            <div className="author-img" style={{backgroundImage: `url(${author1})`}}></div>
                                            <h5><a href="#">by Hetmayar</a></h5>
                                        </div>
                                            <div className="post-link">
                                                <Link to="/newsDetails"><i className="fal fa-arrow-right"></i> Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-blog-post format-video video-post">
                                    <div className="post-featured-thumb bg-cover" style={{ backgroundImage: `url(${post3})` }}>
                                        <div className="video-play-btn">
                                            <span style={{ cursor: 'pointer' }} className="play-video popup-video" onClick={() => setOpen(true)}><i className="fas fa-play"></i></span>
                                        </div>
                                    </div>
                                    <div className="post-content">
                                        <div className="post-cat">
                                            <Link to="/news">Engineering</Link>
                                        </div>
                                        <h2><Link to="/newsDetails">Madencilik sektörü 15 milyar dolar ihracat hedefliyor</Link></h2>
                                        <div className="post-meta">
                                            <span><i className="fal fa-comments"></i>35 Comments</span>
                                            <span><i className="fal fa-calendar-alt"></i>12th May 2020</span>
                                        </div>
                                        <p>Orta Vadeli Program'ın (OVP) yatırım ortamının iyileştirilmesine yönelik politikaları hakkında görüş bildiren Türkiye Madenciler Derneği (TMD) Yönetim Kurulu Başkanı Ali Emiroğlu, tüm sektörlerde bir üretim atılımı gerçekleştirmenin itici gücünün madencilik sektöründe yapılan üretim olacağının altını çizdi.</p>
                                        <div className="d-flex justify-content-between align-items-center mt-30">
                                            <div className="author-info">
                                            <div className="author-img" style={{backgroundImage: `url(${author1})`}}></div>
                                            <h5><a href="#">by Salman</a></h5>
                                        </div>
                                            <div className="post-link">
                                                <a href="/newsDetails"><i className="fal fa-arrow-right"></i> Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-blog-post">
                                    <div className="post-featured-thumb bg-cover" style={{ backgroundImage: `url(${post4})` }}></div>
                                    <div className="post-content">
                                        <div className="post-cat">
                                            <Link to="#">Interior Design</Link>
                                        </div>
                                        <h2><Link to="/newsDetails">Türkiye'nin ilk bor karbür tesisi 19 Mart’ta Bandırma’da açılacak</Link></h2>
                                        <div className="post-meta">
                                            <span><i className="fal fa-comments"></i>35 Comments</span>
                                            <span><i className="fal fa-calendar-alt"></i>24th March 2019</span>
                                        </div>
                                        <p>Türkiye'nin ilk ve stratejik tesislerinden biri olacak bor karbür tesisi, Balıkesir'in Bandırma ilçesinde Cumhurbaşkanı Recep Tayyip Erdoğan'ın katılımıyla 19 Mart Pazar günü açılacak. Enerji ve Tabii Kaynaklar Bakanlığından yapılan açıklamaya göre, dünya bor rezervinin yüzde 73'üne sahip olan Türkiye'de, Eti Maden geçen yıl 1 milyar 321 milyon dolarlık satış geliriyle rekor kırdı.</p>
                                        <div className="d-flex justify-content-between align-items-center mt-30">
                                            <div className="author-info">
                                            <div className="author-img" style={{backgroundImage: `url(${author1})`}}></div>
                                            <h5><a href="#">by Hetmayar</a></h5>
                                        </div>
                                            <div className="post-link">
                                                <Link to="/newsDetails"><i className="fal fa-arrow-right"></i> Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-blog-post quote-post format-quote">
                                    <div className="post-content text-white bg-cover">
                                        <div className="quote-content">
                                            <div className="icon">
                                                <i className="fas fa-quote-left"></i>
                                            </div>
                                            <div className="quote-text">
                                                <h2>Daha fazla madencilik haberleri için bizi takip edin.</h2>
                                                <div className="post-meta">
        
                                                <span><i className="fal fa-comments"></i>35 Comments</span>
                                                <span><i className="fal fa-calendar-alt"></i>24th March 2019</span>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="page-nav-wrap mt-60 text-center">
                                <ul>
                                    <li><Link to="#"><i className="fal fa-long-arrow-left"></i></Link></li>
                                    <li><Link to="#">01</Link></li>
                                    <li><Link to="#">02</Link></li>
                                    <li><Link to="#">..</Link></li>
                                    <li><Link to="#">10</Link></li>
                                    <li><Link to="#">11</Link></li>
                                    <li><Link to="#"><i className="fal fa-long-arrow-right"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                        {/* <Sidebar/> */}
                    </div>
                </div>
            </section>
        </>

    )
}

export default NewsWrapper;