import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import portfolioTwoData from "./portfolioTwoData";
import { Link } from "react-router-dom";
import sectionBg from '../../assets/img/project-bg.jpg';
import { Navigation } from "swiper";
import projectData from "../../data/projectData";
const PortfolioTwo = () => {
    function removeTurkishChars(text) {
        const turkishChars = {
          ğ: "g",
          Ğ: "G",
          ü: "u",
          Ü: "U",
          ş: "s",
          Ş: "S",
          ı: "i",
          İ: "I",
          ö: "o",
          Ö: "O",
          ç: "c",
          Ç: "C",
        };
    
        return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, (match) => turkishChars[match]);
      }
    return (
        <section className="project-carousel-wrapper bg-cover section-padding" style={{backgroundImage: `url(${sectionBg})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-8">
                        <div className="block-contents">
                            <div className="section-title">
                                <h5 className="textleft text-white">Projeler</h5>
                                <span>Güncel Projeler</span>
                                <h2 className="text-white">Şu Anki Projeleri İnceleyelim</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="project-carousel-card-active text-white">
                <Swiper 
                    slidesPerView={3} 
                    loop
                    navigation
                    modules={[Navigation]}
                    breakpoints={{
                        "@0.00": {
                          slidesPerView: 1,
                          spaceBetween: 20,
                          
                        },
                        "@0.75": {
                          slidesPerView: 2,
                          spaceBetween: 20,
                          
                        },
                        "@1.00": {
                          slidesPerView: 2,
                          spaceBetween: 20,
                          
                        },
                        "@1.50": {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                      }}
                >
                {
                    projectData.map((data) => (
                        <SwiperSlide className="single-project-card" key={data.id}>
                            <Link to="/projectDetails" className="project-thumb bg-cover" style={{backgroundImage: `url(${data.img})`}}
                            ></Link>
                            <div className="contents">
                                <div className="project-number">
                                    <h2>{data.index}</h2>
                                </div>
                                <div className="project-details">
                                    {/* <span>{data.category}</span> */}
                                    <h3><Link to={`/projectDetails/${removeTurkishChars(data.title)
                        .replace(/\s+/g, "-")
                        .toLowerCase()}`}>{data.title}</Link></h3>
                                    {/* <p>Client: {data.client}</p> */}
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                }
                </Swiper>
            </div>
        </section>
    )
}

export default PortfolioTwo;