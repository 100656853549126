import {v4 as uuidv4} from 'uuid';

const categoriesData = [
    {
        id: uuidv4(),
        name: 'Metalik Madenler',
        link: '#',
        number: '23',
    },

    {
        id: uuidv4(),
        name: 'Cevher Madenciliği',
        link: '#',
        number: '25',
    },

    {
        id: uuidv4(),
        name: 'Taşocakçılığı',
        link: '#',
        number: '14',
    },

    {
        id: uuidv4(),
        name: 'Tuz Madenciliği',
        link: '#',
        number: '05',
    },


    {
        id: uuidv4(),
        name: 'Elmas Madenciliği',
        link: '#',
        number: '06',
    },

    {
        id: uuidv4(),
        name: 'Uranyum Madenciliği',
        link: '#',
        number: '10',
    },

    
]

export default categoriesData