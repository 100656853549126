import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderOne from '../Components/Header/HeaderOne'
import bannerBg from '../assets/img/proje.png';
import PageBanner from '../Components/PageBanner';
import Details from '../Components/Services/Details';
import projectDataService from '../data/projectData';
import { useParams } from 'react-router-dom';

const ServiceDetails = () => {
  const { title } = useParams();

  function removeTurkishChars(text) {
      const turkishChars = {
          ğ: "g",
          Ğ: "G",
          ü: "u",
          Ü: "U",
          ş: "s",
          Ş: "S",
          ı: "i",
          İ: "I",
          ö: "o",
          Ö: "O",
          ç: "c",
          Ç: "C",
      };

      return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, (match) => turkishChars[match]);
  }

    const projects = projectDataService.find(
      (projectData) =>
        removeTurkishChars(projectData.title).replace(/\s+/g, "-").toLowerCase() ===
        title
    );
  return (
    <>
        <HeaderOne/>
        <PageBanner title={projects.title} bannerBg={bannerBg} currentPage='Proje Detay' />
        
        <Details/>
        <FooterTwo/>
    </>
  )
}

export default ServiceDetails;