import { useState } from "react";
import { Link } from "react-router-dom";
import mainLogo from '../../assets/img/promad_logo.svg';
import MobileMenu from "./MobileMenu";
import OffsetMenu from "./OffsetMenu";

const HeaderOne = () => {
  const [search, setSearch] = useState(true);
  const [offset, setOffset] = useState(true)
  const [mobileMenu, setMobileMenu] = useState(true);

  const handleSearch = () => {
    setSearch(!search);
  }

  const handleOffset = (e) => {
    e.preventDefault();
    setOffset(!offset);
  }

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  }
  return (
    <>
    <OffsetMenu offset={offset} handleOffset={handleOffset}  />
    <header className="header-wrap header-1">
        <div className="container-fluid d-flex justify-content-between align-items-center">
            <div className="logo">
                <Link to='/'>
                    <img src={mainLogo} alt="logo" style={{width:'300px' , margin:'10px'}}/>
                </Link>
            </div>
            <div className="header-right-area d-flex">
                <div className="main-menu d-none d-xl-block">
                    <ul>
                        <li><a href="/">Anasayfa</a>
                            {/* <ul className="sub-menu">
                                <li><Link to="/">homepage 1</Link></li>
                       
                            </ul> */}
                        </li>
                        <li><a href="/about">KURUMSAL <i className="fal fa-plus"></i></a>
                            <ul className="sub-menu">
                                <li><Link to="/about">Hakkımızda</Link></li>
                                <li><Link to="/faq">SSS</Link></li>
                                <li><a href="/galery">Galeri</a> </li>
                                {/* <li><Link to="/projects">Referanslar</Link></li> */}
                                {/* <li><Link to="/pricing">Kalite Politikamız</Link></li> */}
                            </ul>
                        </li>
                        <li><Link to="/services">FAALİYET ALANLARI</Link></li>
                        <li><a href="/projects">PROJELERİMİZ 
                        {/* <i className="fal fa-plus"></i> */}
                        </a>
                            {/* <ul className="sub-menu"> */}
                                {/* <li><Link to="/Team">team</Link></li> */}
                                {/* <li><Link to="/faq">faq</Link></li>
                                <li><Link to="/projects">projects</Link></li> */}
                                {/* <li><Link to="/pricing">Pricing</Link></li> */}
                            {/* </ul> */}
                        </li>
                        
                        <li><Link to="/news">Haberler</Link></li>
                        <li><Link to="/contact">İLETİŞİM</Link></li>
                       
                    </ul>
                </div>
                <div className="header-right-elements d-flex align-items-center justify-content-between">
               
                    <span onClick={handleOffset} className="side-menu-toggle d-none d-xl-block"><i className="fal fa-bars"></i></span>
                    <div className="d-inline-block ms-4 d-xl-none">
                        <div className="mobile-nav-wrap">                    
                            <div id="hamburger" onClick={handleMobileMenu}>
                                <i className="fal fa-bars"></i>
                            </div>
                            <MobileMenu mobileMenu={mobileMenu} handleMobileMenu={handleMobileMenu} />
                        </div>
                        <div className="overlay"></div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    </>
  )
}

export default HeaderOne;