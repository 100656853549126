import React from 'react'
import { Link } from 'react-router-dom'

const MobileMenu = ({mobileMenu, handleMobileMenu}) => {
  return (
    <div className={mobileMenu ? 'mobile-nav' : 'mobile-nav show'}>
        <button onClick={handleMobileMenu} type="button" className="close-nav">
            <i className="fal fa-times-circle"></i>
        </button>
        <nav className="sidebar-nav">
            <ul className="metismenu " id="mobile-menu">
                <li><a  href="/">Anasayfa</a>
                    {/* <ul className="sub-menu">
                        <li><Link to="/">homepage 1</Link></li>
                    </ul> */}
                </li>
                <li><Link className="has-arrow" to="/about">KURUMSAL <i className="fal fa-plus"></i></Link>
                            <ul className="sub-menu">
                                <li><Link to="/about">Hakkımızda</Link></li>
                                <li><Link to="/faq">SSS</Link></li>
                                <li><Link to="/galery">Galeri</Link> </li>
                                {/* <li><Link to="/projects">Referanslar</Link></li> */}
                                {/* <li><Link to="/pricing">Kalite Politikamız</Link></li> */}
                            </ul>
                        </li>
                        <li><Link to="/services">Faaliyet Alanları</Link></li>
                        <li><Link to="/projects">Projelerimiz  </Link> </li>

                        <li><Link to="/news">Haberler</Link></li>
                        <li><Link to="/contact">İletişim</Link></li>

               
            </ul>
        </nav>

        <div className="action-bar">
            <a href="mailto:info@example.com"><i className="fal fa-envelope"></i>info@webmail.com</a>
            <a href="tel:123-456-7890"><i className="fal fa-phone"></i>987-098-098-09</a>
            <Link to='/contact' className="d-btn theme-btn">Bizimle İletişime Geçin</Link>
        </div>
    </div>    
     
  )
}

export default MobileMenu