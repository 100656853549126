import {v4 as uuidv4} from 'uuid';
import portfolio1 from '../../assets/img/project/ironore.png';
import portfolio2 from '../../assets/img/project/coalmine.png';
import portfolio3 from '../../assets/img/project/tapulimine.png';
import portfolio4 from '../../assets/img/project/goldmine.png';

const portfolioTwoData = [
    {
        id: uuidv4(),
        img: portfolio1,
        category: 'Balmoral South Iron Ore Project',
        location: 'Australia',
        client: 'Gerding Development',
        index: '01',
    },

    {
        id: uuidv4(),
        img: portfolio2,
        category: 'Boikarabelo Coal Mine Project',
        location: 'Limpopo',
        client: 'Southeast Land',
        index: '02',
    },

    {
        id: uuidv4(),
        img: portfolio3,
        category: 'Tapuli Mine Project',
        location: 'Sweden',
        client: 'Green Dopler',
        index: '03',
    },

    {
        id: uuidv4(),
        img: portfolio4,
        category: 'Tartan Lake Gold Mine Project',
        location: 'Canada',
        client: 'Gerding Development',
        index: '04',
    },

]

export default portfolioTwoData;