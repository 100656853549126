import {v4 as uuidv4} from 'uuid';
import img1 from "../assets/img/new-detail/Haberdetay – 1.png"
import img2 from "../assets/img/new-detail/Haber detay – 2.png"
const newsData = [
    {
        id: uuidv4(),
        title: "Türkiye'nin ilk bor karbür tesisi 19 Mart’ta Bandırma’da açılacak",
        date : "24 Mart 2023",
        description_1 : "Türkiye'nin ilk ve stratejik tesislerinden biri olacak bor karbür tesisi, Balıkesir'in Bandırma ilçesinde Cumhurbaşkanı Recep Tayyip Erdoğan'ın katılımıyla 19 Mart Pazar günü açılacak. Enerji ve Tabii Kaynaklar Bakanlığından yapılan açıklamaya göre, dünya bor rezervinin yüzde 73'üne sahip olan Türkiye'de, Eti Maden geçen yıl 1 milyar 321 milyon dolarlık satış geliriyle rekor kırdı",
        news_img_data: img1,
      
    },

    {
        id: uuidv4(),
        title: "Kritik minerallerin piyasa değerini yükseliyor",
        date : "24 Mart 2023",
        description_1 : "Dünyada yeşil dönüşüm için gerekli temiz enerji teknolojilerinde kullanılan kritik minerallerin piyasa büyüklüğü 2040'a kadar yüzde 137 artarak 770 milyar dolara ulaşacak.",
        news_img_data: img2,
       
    },

    // {
    //     id: uuidv4(),
    //     thumb: thumb3,
    //     title: 'Beyond Outpaces Stock Market You Should Know',
    //     catName: 'Marketing',
    //     btnLink: '/newsDetails',
    //     authorName: 'Busico',
    //     authorImg: authorImg,
    //     date: '22 Feb 2022',
    //     comments: '05 Comments'
    // },
]

export default newsData;