import React from 'react'
import { Link } from 'react-router-dom'
import FooterCta from './FooterCta'
import FooterOneCopyrights from './FooterOneCopyrights'
import footerMap from '../../assets/img/footer-map.png';

const FooterTwo = () => {
  return (
    <footer className="footer-2 footer-wrap">
        <div className="footer-widgets-wrapper text-white">
            <div className="container">
                <div className="row">
                    <div className="col-xl-3 pe-xl-0 col-sm-6 col-12">
                        <div className="single-footer-wid site_info_widget">
                            <div className="wid-title">
                                <h3>Bizimle İletişime Geçin</h3>
                            </div>
                            <div className="contact-us">
                                <div className="single-contact-info">
                                    <div className="icon">
                                        <i className="fal fa-phone"></i>
                                    </div>
                                    <div className="contact-info">
                                        <p>+90 555 555 55 55</p>
                                      
                                    </div>
                                </div>
                                <div className="single-contact-info">
                                    <div className="icon">
                                        <i className="fal fa-envelope"></i>
                                    </div>
                                    <div className="contact-info">
                                        <p>info@promad.com</p>
                                  
                                    </div>
                                </div>
                                <div className="single-contact-info">
                                    <div className="icon">
                                        <i className="fal fa-map-marker-alt"></i>
                                    </div>
                                    <div className="contact-info">
                                        <p>
                                            Turkiye</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 offset-xl-1 col-xl-3 ps-xl-5 col-12">
                        <div className="single-footer-wid">
                            <div className="wid-title">
                                <h3>Linkler</h3>
                            </div>
                            <ul>
                            <li><Link to="/about">Hakkımızda</Link></li>
                                        <li><Link to="/services">Faaliyet Alanları</Link></li>
                                        <li><Link to="/projects">Projelerimiz</Link></li>
                                        <li><Link to="/faq">SSS</Link></li>
                                        <li><Link to="/news">Galeri</Link></li>
                                        <li><Link to="/contact">İletişim</Link></li>
                            </ul>
                        </div>
                    </div> 

                    <div className="col-sm-6 col-xl-4 offset-xl-1 col-12">                        
                        <div className="single-footer-wid newsletter_widget">
                            <div className="wid-title">
                                <h3>Maden Ocaklarımız</h3>
                            </div>
                            <div className="map-location">
                                <img src={footerMap} alt=""/>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>

        <FooterCta/>
        <FooterOneCopyrights/>
    </footer> 
  )
}

export default FooterTwo