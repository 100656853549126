import React from 'react';
import animationData from "../assets/img/loading/animatonjsn.json";
import Lottie from 'lottie-react';

const Preloader = () => {
  return (
    <>
      <div id="preloader" className="preloader">
        <div className="animation-preloader">
          
          <Lottie animationData={animationData} style={{ width: 500, height: 500 }} />
          
          <div className="txt-loading">
            <span data-text-preloader="P" className="letters-loading">
              P
            </span>
            <span data-text-preloader="R" className="letters-loading">
              R
            </span>
            <span data-text-preloader="O" className="letters-loading">
              O
            </span>
            <span data-text-preloader="M" className="letters-loading">
              M
            </span>
            <span data-text-preloader="A" className="letters-loading">
              A
            </span>
            <span data-text-preloader="D" className="letters-loading">
              D
            </span>
          </div>
          {/* <p className="text-center">Loading</p> */}
        </div>
        <div className="loader">
          <div className="row">
            <div className="col-3 loader-section section-left">
              <div className="bg"></div>
            </div>
            <div className="col-3 loader-section section-left">
              <div className="bg"></div>
            </div>
            <div className="col-3 loader-section section-right">
              <div className="bg"></div>
            </div>
            <div className="col-3 loader-section section-right">
              <div className="bg"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Preloader;
