import {v4 as uuidv4} from 'uuid';
import img1 from '../../assets/img/team/teammember1.jpeg';


const teamOneData = [
    {
        id: uuidv4(),
        img: img1,
        name: 'Abraham Reza',
        designation: 'Contructor',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: '#'
    },

    {
        id: uuidv4(),
        img: img1,
        name: 'Salman Ahmed',
        designation: 'Engineer',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: '#'
    },

    {
        id: uuidv4(),
        img: img1,
        name: 'Daniel David',
        designation: 'Mechanic',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: '#'
    },

    
]

export default teamOneData;